//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: ['bonus'],
  methods: {
    activateBonus: function activateBonus() {},
    closeBonusModal: function closeBonusModal() {
      this.$store.dispatch('modals/setBonusDetails', {
        isOpen: false,
        data: null
      });
    }
  },
  computed: {
    activeBonus: function activeBonus() {
      return this.$store.getters['balance/activeBonus'];
    },
    isActiveBonus: function isActiveBonus() {
      if (this.activeBonus) {
        return this.activeBonus.id === this.bonus.id;
      }

      return false;
    },
    thumbStyle: function thumbStyle() {
      return {
        right: this.right,
        borderRadius: '2px',
        backgroundColor: '#fff',
        width: '2px',
        opacity: 1,
        zIndex: 4
      };
    },
    barStyle: function barStyle() {
      return {
        right: this.right,
        borderRadius: '4px',
        backgroundColor: '#fff',
        width: '1px',
        opacity: 0.1,
        zIndex: 4
      };
    }
  }
};